<template>
  <div class="map-location">
    <el-input
        v-model="parkingLocation"
        placeholder="车场经纬度"
        readonly="readonly"
        @focus="showModal"
    ></el-input>

    <el-dialog
        :append-to-body="true"
        :visible.sync="dialogVisible"
        class="common-dialog"
        title="获取经纬度"
    >
      <div>
        <el-autocomplete
            v-model="keyword"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            clearable
            placeholder="根据地址关键字搜索"
            popper-class="autoAddressClass"
            style="width: 300px; margin-right: 10px"
            @select="handleSelect"
        >
          <template slot-scope="{ item }">
            <div style="overflow: hidden; padding-top: 5px">
              <div
                  class="title"
                  style="line-height: 1; font-size: 16px; margin-top: 5px"
              >
                {{ item.title }}
              </div>
              <span
                  class="address ellipsis"
                  style="font-size: 13px; color: #999"
              >{{ item.address }}</span
              >
            </div>
          </template>
        </el-autocomplete>
        <el-input
            :value="point.lng"
            disabled
            placeholder="经度"
            style="width: 100px; margin-left: 10px"
        />
        <el-input
            :value="point.lat"
            disabled
            placeholder="纬度"
            style="width: 100px; margin-left: 10px"
        />
      </div>
      <div id="map-container" class="map-container"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doMakeSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LoadMap from "@/common/LoadMap";

export default {
  name: "get-location",
  props: {
    value: {
      type: String,
      default: "",
    },
    before: {
      type: Function,
      default() {
        return () => {
          return false;
        };
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      map: null,
      mk: null,
      keyword: "",
      point: {},
      parkingLocation: "",
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initMap();
        });
      }
    },
    value(newVal) {
      this.parkingLocation = newVal;
    },
  },
  async mounted() {
    // await LoadMap("oW2UEhdth2tRbEE4FUpF9E5YVDCIPYih");
    // await LoadMap("jTFQ8ncSGEmKHndCIph9Qjo4XopIUYWs");
    const ak = process.env.VUE_APP_BASE_KEY;
    await LoadMap(ak);
  },
  methods: {
    doMakeSure() {
      if (JSON.stringify(this.point) === "{}") {
        this.$emit("makesure", "");
      } else {
        this.parkingLocation = this.point.lng + "," + this.point.lat;
        this.$emit("makesure", this.point.lng + "," + this.point.lat);
      }
      this.dialogVisible = false;
    },
    getAddrByPoint(point) {
      // const that = this;
      // const geco = new window.BMap.Geocoder();

      // geco.getLocation(point, function (res) {
      //   that.mk.setPosition(point);
      //   that.map.panTo(point);
      //   that.keyword = res.address;
      //   that.point = point;
      // });

      const that = this;
      const geco = new window.BMap.Geocoder();
      const convertor = new window.BMap.Convertor();
      geco.getLocation(point, function (res) {
        that.mk.setPosition(point);
        that.map.panTo(point);
        that.keyword = res.address;
        console.log("百度地图坐标系：", point);
        convertor.translate([point], 5, 3, function (data) {
          if (data.status === 0) {
            console.log("转换后的坐标系：", data.points[0]);
            that.point = data.points[0];
          }
        });
        // that.point = point;
      });
    },

    querySearchAsync(str, cb) {
      var options = {
        onSearchComplete: function (res) {
          var s = []; //  == BMAP_STATUS_SUCCESS
          if (local.getStatus() === 0) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i));
            }
            cb(s);
          } else {
            cb(s);
          }
        },
      };
      var local = new window.BMap.LocalSearch(this.map, options);
      local.search(str);
    },

    handleSelect(item) {
      const that = this;
      const point = item.point;

      this.keyword = item.address + item.title;
      this.map.clearOverlays();
      this.mk = new window.BMap.Marker(point);
      this.map.addOverlay(this.mk);
      this.map.panTo(point);

      // 将百度地图坐标系转换为GCJO2坐标系
      const convertor = new window.BMap.Convertor();
      convertor.translate([point], 5, 3, function (data) {
        if (data.status === 0) {
          that.point = data.points[0];
        }
      });
    },

    initMap() {
      const that = this;
      console.log('initMap', this.map)

      const BMap = window.BMap;
      this.map = new BMap.Map('map-container', {enableMapClick: false});
      var point = new BMap.Point(103.27, 25.56);
      this.map.centerAndZoom(point, 14);

      // 3、设置图像标注并绑定拖拽标注结束后事件
      this.mk = new BMap.Marker(point, {enableDragging: true});
      this.map.addOverlay(this.mk);
      this.mk.addEventListener("dragend", function (e) {
        that.getAddrByPoint(e.point);
      });

      this.map.addEventListener("click", function (e) {
        that.getAddrByPoint(e.point);
      });
    },
    showModal() {
      console.log(this.before())
      if (this.before()) {
        console.log('showModal')
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.map-container {
  height: 500px;
  margin-top: 10px;
  border: 1px solid #ddd;
}

.autoAddressClass {
  /deep/ li {
    i.el-icon-search {
      margin-top: 11px;
    }

    /deep/ .mgr10 {
      margin-right: 10px;
    }

    /deep/ .title {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    /deep/ .address {
      line-height: 1;
      font-size: 12px;
      color: #b4b4b4;
      margin-bottom: 5px;
    }
  }
}
</style>
